import Slide_0 from "../assets/Strandbad/slide_0.jpg";
import Slide_1 from "../assets/Strandbad/slide_1.jpg";
import Slide_2 from "../assets/Strandbad/slide_2.jpg";
import Slide_3 from "../assets/Strandbad/slide_3.jpg";
import Slide_4 from "../assets/Strandbad/slide_4.jpg";
import Slide_5 from "../assets/Strandbad/slide_5.jpg";
import Slide_6 from "../assets/Strandbad/slide_6.jpg";
import { H1 } from "./StyledComponents/StyledHeadlines";
import { useState } from "react";
import styled from "styled-components";
import { mobile_width_m } from "./Dimensions";

export function Strandbad() {
    const [selectedPicture, setSelectedPicture] = useState<string>(Slide_0);

    const images = [
        {
            image: Slide_0,
            alt: "Strandbad Slide 0",
        },
        {
            image: Slide_1,
            alt: "Strandbad Slide 1",
        },
        {
            image: Slide_2,
            alt: "Strandbad Slide 2",
        },
        {
            image: Slide_3,
            alt: "Strandbad Slide 3",
        },
        {
            image: Slide_4,
            alt: "Strandbad Slide 4",
        },
        {
            image: Slide_5,
            alt: "Strandbad Slide 5",
        },
        {
            image: Slide_6,
            alt: "Strandbad Slide 6",
        },
    ];

    const Image = (props: { image: string; alt: string }) => {
        const { image, alt } = props;
        return (
            <img
                src={image}
                alt={alt}
                onClick={() => setSelectedPicture(image)}
            />
        );
    };

    const ImageGallery = styled.div`
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        img {
            width: 65vw;
        }

        @media screen and (max-width: ${mobile_width_m}) {
            img {
                width: 90vw;
            }
            flex-direction: column;
        }
    `;

    const SideGallery = styled.div`
        display: flex;
        flex-direction: column;
        max-height: 700px;
        overflow-y: scroll;
        img {
            width: 10vw;
            padding-bottom: 2vh;
            padding-right: 5px;
            cursor: pointer;
        }

        ::-webkit-scrollbar {
            width: 5px;
            height: 2px;
        }
        ::-webkit-scrollbar-button {
            width: 0px;
            height: 0px;
        }
        ::-webkit-scrollbar-thumb {
            background: #e1e1e1;
            border: 0px none #ffffff;
            border-radius: 50px;
        }
        ::-webkit-scrollbar-thumb:hover {
            background: #ffffff;
        }
        ::-webkit-scrollbar-thumb:active {
            background: #000000;
        }
        ::-webkit-scrollbar-track {
            background: #666666;
            border: 0px none #ffffff;
            border-radius: 50px;
        }
        ::-webkit-scrollbar-track:hover {
            background: #666666;
        }
        ::-webkit-scrollbar-track:active {
            background: #333333;
        }
        ::-webkit-scrollbar-corner {
            background: transparent;
        }

        @media screen and (max-width: ${mobile_width_m}) {
            flex-direction: row;
            margin-top: 20px;

            img {
                min-width: 150px;
                padding-bottom: 0;
                cursor: pointer;
                overflow-x: scroll;
                padding-right: 10px;
            }
        }
    `;

    return (
        <div className="paddingTop">
            <H1>Strandbad</H1>
            <ImageGallery>
                <img src={selectedPicture} alt="Selected" />
                <SideGallery>
                    {images.map((image) => (
                        <Image
                            alt={image.alt}
                            image={image.image}
                            key={`gallery_image` + image.alt}
                        />
                    ))}
                </SideGallery>
            </ImageGallery>
        </div>
    );
}
