export const handleVisibilityChange = (
    { visible, directionX, directionY },
    elementID
) => {
    const element = document.getElementsByClassName(elementID);
    if (visible) {
        for (let i = 0; i < element.length; i++) {
            element[i].style.opacity = 1;
        }
    }
};
