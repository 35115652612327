import styled from "styled-components";
import { mobile_width_m } from "../Dimensions";

export const LandingCard = styled.div`
    opacity: 0;
    transition: 2s ease opacity;
    width: 20vw;
    background-color: white;
    border-radius: 25px;
    border: 5px solid #fda933;
    color: #fda933;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 10px;

    div,
    p {
        font-size: 1rem;
        color: #a9a9a9;
    }
    .weather {
        color: #fda933;
        font-size: 2rem !important;
        font-family: "Lily Script One" !important;
    }
    .waterTemperatur {
        color: #80cee1;
        font-size: 2rem !important;
        font-family: "Lily Script One" !important;
    }
    -webkit-box-shadow: 4px 6px 5px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 4px 6px 5px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 4px 6px 5px 0px rgba(0, 0, 0, 0.5);

    h1 {
        @media screen and (max-width: ${mobile_width_m}) {
            font-size: 24px;
        }
    }

    @media screen and (max-width: ${mobile_width_m}) {
        min-width: 250px;
        margin: 25px 10px;
    }
`;
