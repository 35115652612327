import React from 'react';
import '../styles/Datenschutz.css';

export default class Datenschutz extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		return (
			<div className="Page Datenschutz">
				<h1 style={{ marginBottom: '20px', color: '#fda933', textAlign: 'center' }}>Datenschutz</h1>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
					}}
				>
					<div style={{ width: '1000px' }}>
						<p>
							Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. Wir verarbeiten Ihre
							Daten daher ausschließlich auf Grundlage der gesetzlichen Bestimmungen (DSGVO, TKG 2003). In
							diesen Datenschutzinformationen informieren wir Sie über die wichtigsten Aspekte der
							Datenverarbeitung im Rahmen unserer Website.
						</p>
						<h2 style={{ marginBottom: '20px', color: '#fda933' }}>Web Analyse</h2>
						<p>
							Wir verwenden Google Analytics, um die Website-Nutzung zu analysieren. Die daraus gewonnenen
							Daten werden genutzt, um unsere Website sowie Werbemaßnahmen zu optimieren. Google Analytics
							ist ein Webanalysedienst, der von Google Inc. (1600 Amphitheatre Parkway, Mountain View, CA
							94043, United States) betrieben und bereitgestellt wird. Google verarbeitet die Daten zur
							Website-Nutzung in unserem Auftrag und verpflichtet sich vertraglich zu Maßnahmen, um die
							Vertraulichkeit der verarbeiteten Daten zu gewährleisten. Während Ihres Website-Besuchs
							werden u.a. folgende Daten aufgezeichnet:
						</p>
						<ul>
							<li>Aufgerufene Seiten</li>
							<li>Bestellungen inkl. des Umsatzes und der bestellten Produkte </li>
							<li>
								Die Erreichung von "Website-Zielen" (z.B. Kontaktanfragen und Newsletter-Anmeldungen){' '}
							</li>
							<li>
								Ihr Verhalten auf den Seiten (beispielsweise Klicks, Scroll-Verhalten und Verweildauer){' '}
							</li>
							<li>Ihr ungefährer Standort (Land und Stadt) </li>
							<li>Ihre IP-Adresse (in gekürzter Form, sodass keine eindeutige Zuordnung möglich ist)</li>
							<li>
								Technische Informationen wie Browser, Internetanbieter, Endgerät und Bildschirmauflösung{' '}
							</li>
							<li>
								Herkunftsquelle Ihres Besuchs (d.h. über welche Website bzw. über welches Werbemittel
								Sie zu uns gekommen sind){' '}
							</li>
						</ul>

						<p>
							Diese Daten werden an einen Server von Google in den USA übertragen. Google beachtet dabei
							die Datenschutzbestimmungen des „EU-US Privacy Shield“-Abkommens. Google Analytics speichert
							Cookies in Ihrem Webbrowser für die Dauer von zwei Jahren seit Ihrem letzten Besuch. Diese
							Cookies enthaltene eine zufallsgenerierte User-ID, mit der Sie bei zukünftigen
							Website-Besuchen wiedererkannt werden können. Die aufgezeichneten Daten werden zusammen mit
							der zufallsgenerierten User-ID gespeichert, was die Auswertung pseudonymer Nutzerprofile
							ermöglicht. Diese nutzerbezogenen Daten werden automatisch nach 14 Monaten gelöscht.
							Sonstige Daten bleiben in aggregierter Form unbefristet gespeichert. Sollten Sie mit der
							Erfassung nicht einverstanden sein, können Sie diese mit der einmaligen Installation des
							Browser-Add-ons zur Deaktivierung von Google Analytics unterbinden.
						</p>
						<p>Quelle: traffic3.net</p>
					</div>
				</div>
			</div>
		);
	}
}
