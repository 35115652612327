import React from "react";
import { Link } from "react-router-dom";

export default class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="FooterContainer">
                <div className="Footer">
                    <div className="address">
                        <h2 style={{ fontWeight: "bold" }}>
                            Strandbad Neumarkt am Wallersee
                        </h2>
                        <p>Uferstraße 3</p>
                        <p>5202 Neumarkt am Wallersee</p>
                        <p>Tel.: +43 660 9442233</p>
                    </div>
                    <div>
                        <h2 style={{ fontWeight: "bold" }}>Navigation</h2>
                        <div className="footerLinks">
                            <Link to="/" onClick={() => window.scrollTo(0, 0)}>
                                Home
                            </Link>
                            <Link to="/Impressum">Impressum</Link>
                            <Link to="/Datenschutz">Datenschutz</Link>
                        </div>
                    </div>

                    <div>
                        <div>
                            <h2 style={{ fontWeight: "bold" }}>Google Maps</h2>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2673.5171323696736!2d13.20849881589162!3d47.92637987382552!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47769e4df25d4b17%3A0xe61a246dfa39829!2sUferstra%C3%9Fe%203%2C%205202%20Neumarkt%20am%20Wallersee!5e0!3m2!1sde!2sat!4v1598796340907!5m2!1sde!2sat"
                                style={{
                                    frameborder: "0",
                                    style: "border:0",
                                    allowfullscreen: "",
                                    ariaHidden: "false",
                                    tabindex: "0",
                                }}
                                id="googleMaps"
                                title="Strandbad Google Maps"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
