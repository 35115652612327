import VisibilityDetector from "react-visibility-detector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faUmbrellaBeach,
    faSun,
    faTableTennis,
    faVolleyballBall,
    faSnowboarding,
} from "@fortawesome/free-solid-svg-icons";
import { H1 } from "./StyledComponents/StyledHeadlines";
import { handleVisibilityChange } from "../functions/VisibilityChange";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { ScrollableFlexBox } from "./StyledComponents/StyledLayout";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

type RentItemProps = {
    faIcon: IconProp;
    price: string;
    title: string;
    index: number;
};

const RentItem = (props: RentItemProps) => {
    const { faIcon, price, title, index } = props;

    const className =
        index % 2 !== 0 ? "landingCardSmall blue" : "landingCardSmall";

    return (
        <div className={className}>
            <FontAwesomeIcon className="cardIcon" icon={faIcon} />
            <div>
                <p className="description">{title}</p>
                <p className="price">{price}</p>
            </div>
        </div>
    );
};

const rentItems = [
    {
        icon: faSnowboarding,
        title: "Stand Up Paddle",
        price: "8€ (0.5h) / 15€ (1h)",
    },
    {
        icon: faVolleyballBall,
        title: "Volleyball",
        price: "5€ (Einsatz)",
    },
    {
        icon: faTableTennis,
        title: "Tischtennis",
        price: "2€",
    },
    {
        icon: faUmbrellaBeach,
        title: "Liegen",
        price: "10€ (5€ Einsatz)",
    },
    {
        icon: faSun,
        title: "Sonnenschirm",
        price: "5€ (2€ Einsatz)",
    },
];

export function RentItems() {
    return (
        <div className="paddingTop">
            <H1>Verleih</H1>
            <VisibilityDetector
                onVisibilityChange={(data) =>
                    handleVisibilityChange(data, "landingCardSmall")
                }
            >
                <ScrollableFlexBox>
                    <div style={{ position: "absolute", right: "10px" }}>
                        <FontAwesomeIcon
                            className="scroll-indicator"
                            icon={faChevronRight}
                            style={{ color: "#a9a9a9" }}
                        />
                    </div>
                    {rentItems.map((item, index) => {
                        return (
                            <RentItem
                                key={item.title}
                                index={index}
                                faIcon={item.icon}
                                title={item.title}
                                price={item.price}
                            />
                        );
                    })}
                </ScrollableFlexBox>
            </VisibilityDetector>
        </div>
    );
}
