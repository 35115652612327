import styled from "styled-components";
import { mobile_width_m } from "../Dimensions";

export const H1 = styled.h1`
    width: 100%;
    text-align: left;
    color: #a9a9a9;
    border-bottom: 1px solid #a9a9a9;
    margin: 20px 0px;    

    @media screen and (max-width: ${mobile_width_m}) {
        font-size: 24px;
    }
`;
