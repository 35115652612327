import styled from "styled-components";
import { mobile_width_m } from "../Dimensions";

export const LandingPageBanner = styled.div`
    position: relative;
    width: 100%;
    height: 60vh;
    background-size: cover;
    display: flex;
    justify-content: center;
    background-position-y: 50%;

    @media screen and (max-width: ${mobile_width_m}) {
        align-items: center;
        height: 30vh;
    }
`;

export const BannerText = styled.h1`
    font-size: 6rem;
    color: white;
    text-shadow: 4px 4px 2px #000000;

    @media screen and (max-width: ${mobile_width_m}) {
        font-size: 2rem;
        height: 2.5rem;
    }
`;

export const LandingContent = styled.div`
    width: 80vw;
    padding: 20px;

    @media screen and (max-width: ${mobile_width_m}) {
        width: 90vw;
        padding: 0;
    }
`;
