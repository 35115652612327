import LandingBanner from "../assets/Startpage/LandingBanner.JPG";
import Camping from "../assets/Camping/slide_0.jpg";

import { H1 } from "./StyledComponents/StyledHeadlines";
import { Informations } from "./Informations";
import { RentItems } from "./RentItems";
import { Restaurant } from "./Restaurant";
import { Strandbad } from "./Strandbad";
import { LandingPageBanner, BannerText, LandingContent } from "./StyledComponents/LandingPage";
import { FlexBox } from "./StyledComponents/StyledLayout";
import styled from "styled-components";

const StartPage = () => {
    return (
        <div className="Page Landing">
            <LandingPageBanner className="landingPageBanner" style={{ backgroundImage: `url("${LandingBanner}")` }}>
                <BannerText className="bannerText">Herzlich Willkommen</BannerText>
            </LandingPageBanner>
            <LandingContent>
                <Informations />
                <Restaurant />
                <RentItems />
                <Strandbad />
                <div className="paddingTop">
                    <H1>Camping</H1>
                    <FlexBox className="itemContent">
                        <CampingImage src={Camping} alt="Restaurant Entrance" />

                        <div className="itemContent">
                            <p>
                                Falls du auf der Suche nach einem Ort zum Urlaub machen bist, bist du bei uns genau richtig. Durch unsere
                                Campingplätze bringst du deinen Urlaub auf das nächste Level. So kannst du jeden Tag das Strandbad in seinem vollen
                                Ausmaß genießen. Wenn du an einen Campingplatz interessiert bist, kannst du uns unter folgender Nummer erreichen +43
                                660 9442233
                            </p>
                        </div>
                    </FlexBox>
                </div>
            </LandingContent>
        </div>
    );
};

export default StartPage;

const CampingImage = styled.img`
    width: 33% !important;
`;
