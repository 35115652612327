import logo from "../assets/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneSquare } from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

const Header = () => {
    const [isMobile, setIsMobile] = useState<boolean>(
        window.outerWidth > 1000 ? false : true
    );

    useEffect(() => {
        window.addEventListener("resize", () => {
            if (window.outerWidth < 1000) {
                setIsMobile(true);
            }
        });
    }, [isMobile]);

    return (
        <div className="headerContainer">
            <div className="header">
                <Link to="/">
                    <img id="headerLogo" src={logo} alt="Header Logo" />
                </Link>
                <div className="headerNav">
                    <a
                        href="https://goo.gl/maps/GkPjuzwtjuhxYbRa9"
                        target="_blank"
                        className="NavItem"
                        rel="noopener noreferrer"
                    >
                        Routenplaner
                    </a>
                    <a className="phone" href="tel:+43 660 9442233">
                        <FontAwesomeIcon
                            id="phoneIcon"
                            icon={faPhoneSquare}
                            style={{
                                color: "rgb(255, 165, 0)",
                                fontSize: "40px",
                            }}
                        />
                        <span
                            className="headerPhonenumber"
                            style={{
                                paddingLeft: "10px",
                            }}
                        >
                            +43 660 9442233
                        </span>
                    </a>
                </div>
            </div>
        </div>
    );
};

/*
 */
export default Header;
