import React from 'react';
import '../styles/Impressum.css';

export default class Impressum extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};
    }
    
    componentDidMount() {
		window.scrollTo(0, 0);
	}

	render() {
		return (
			<div className="Page Impressum">
				<h1 style={{ marginBottom: '20px', color: '#fda933', textAlign: 'center' }}>Impressum</h1>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
					}}
				>
					<div style={{ width: '800px' }}>
						<h2 style={{ marginBottom: '20px', color: '#fda933' }}>Strandbad Neumarkt am Wallersee</h2>
						<p style={{ color: '#fda933' }}>Uferstraße 3</p>
						<p style={{ color: '#fda933' }}>5202 Neumarkt am Wallersee</p>
					</div>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
					}}
				>
					<div style={{ width: '800px' }}>
						<h2 style={{ marginBottom: '20px', color: '#fda933' }}>Kontaktdaten</h2>
						<p style={{ color: '#fda933' }}>Jehona Berisha Veliu</p>
						<a style={{ color: '#fda933' }} href="mailto:Buchhaltung@leimueller.com">
							E-Mail: office@leimueller-linzergasse.at
						</a>
						<p style={{ color: '#fda933' }}>Tel.: +43 660 9442233</p>
					</div>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
					}}
				>
					<div style={{ width: '800px' }}>
						<h2 style={{ marginBottom: '20px', color: '#fda933' }}>Online Referenzen</h2>
						<a
							style={{ color: '#fda933' }}
							href="http://pixabay.com/de/"
							target="_blank"
							rel="noopener noreferrer"
						>
							Pixabay
						</a>
					</div>
				</div>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
					}}
				>
					<div style={{ width: '800px' }}>
						<h2 style={{ marginBottom: '20px', color: '#fda933' }}>Web Design</h2>
						<p style={{ color: '#fda933' }}>Robin Braumann</p>
						<a style={{ color: '#fda933' }} href="mailto:robin.braumann@gmail.com">
							E-Mail: robin.braumann@gmail.com
						</a>
						<p style={{ color: '#fda933' }}>Tel.: +43 680 5034612</p>
					</div>
				</div>
			</div>
		);
	}
}
