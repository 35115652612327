import { H1 } from "./StyledComponents/StyledHeadlines";
import RestaurantImage from "../assets/Restaurant/restaurant.jpg";
import { FlexBox } from "./StyledComponents/StyledLayout";

export function Restaurant() {
    return (
        <div className="paddingTop">
            <H1>Restaurant</H1>
            <FlexBox className="itemContent">
                <div className="itemContent restaurant">
                    <img src={RestaurantImage} alt="Restaurant Entrance" />
                    <p>
                        Nach einem langem Badetag gibt es nichts erfrischenderes
                        als ein kaltes Getränk und eine gute Mahlzeit. Neben dem
                        ausgezeichneten Essen bieten wir dir auch noch die
                        Möglichkeit zum Billard und Darts spielen an. Somit hast
                        du die Wohlfühl-Kombination aus Essen und Unterhaltung.
                    </p>
                    <p>
                        Egal ob Groß oder Klein, wir bieten Erholung für
                        Jedermann.
                        Unsere Öffnungszeiten sind sehr wetterabhängig, deswegen
                        empfehlen wir, dass Du uns anrufst, wenn du außerhalb
                        der Saison vorbeischauen möchtest. Außerhalb der Saison
                        haben wir Dienstag und Mittwoch geschlossen. Wir sind
                        unter der Nummer +43 660 9442233 für eure Anliegen
                        erreichbar.
                    </p>
                </div>
            </FlexBox>
        </div>
    );
}
