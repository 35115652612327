import styled from "styled-components";
import { mobile_width_m } from "../Dimensions";

export const FlexBox = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: ${mobile_width_m}) {
        flex-direction: column;
        align-items: center;
    }
`;

export const Grid = styled.div`
    display: grid;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: ${mobile_width_m}) {
        flex-direction: column;
        align-items: center;
    }
`;

export const ScrollableFlexBox = styled(FlexBox)`
    @media screen and (max-width: ${mobile_width_m}) {
        width: 100%;
        flex-direction: row !important;
        overflow-y: scroll;
    }
`;
