import { useState, useEffect } from "react";
import VisibilityDetector from "react-visibility-detector";
import { useOpenWeather } from "react-open-weather";
import { H1 } from "./StyledComponents/StyledHeadlines";
import { handleVisibilityChange } from "../functions/VisibilityChange";
import { ScrollableFlexBox } from "./StyledComponents/StyledLayout";
import { LandingCard } from "./StyledComponents/LandingCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

enum Months {
    JANUARY,
    FEBRUARY,
    MARCH,
    APRIL,
    MAY,
    JUNE,
    JULY,
    AUGUST,
    SEPTEMBER,
    OCTOBER,
    NOVEMBER,
    DECEMBER,
}

type Measurements = {
    W: number;
    D: string;
};

type BathingWater = {
    BADEGEWAESSERID: string;
    MESSWERTE: Measurements[];
};

type Countie = {
    BADEGEWAESSER: BathingWater[];
};

export function Informations() {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [waterTemperature, setWaterTemperature] = useState({
        waterTemperature: 0,
        date: "",
    });

    const { data } = useOpenWeather({
        key: "e8cd37b87d643a201148e767f4b0b8e5",
        lat: "47.92668340924781",
        lon: "13.210641698207471",
        lang: "en",
        unit: "metric", // values are (metric,
    });

    const [weather, setWeather] = useState({
        date: undefined,
        humidity: undefined,
        temperature: undefined,
    });

    useEffect(() => {
        const recentDate = new Date();

        if (recentDate.getMonth() >= Months.MARCH && recentDate.getMonth() <= Months.SEPTEMBER) {
            if (recentDate.getHours() >= 7 || recentDate.getHours() <= 22) {
                setIsOpen(true);
            }
        }
    }, [isOpen]);

    if (data && weather.temperature === undefined) {
        setWeather({
            date: data.current.date,
            humidity: data.current.humidity,
            temperature: data.current.temperature.current,
        });
    }

    useEffect(() => {
        (async () => {
            const readableStream = await fetch("https://thingproxy.freeboard.io/fetch/https://www.ages.at/typo3temp/badegewaesser_db.json");

            const counties = await readableStream.json();
            const salzburg: Countie = counties.BUNDESLAENDER[6];

            //Wallersee ID
            const wallersee = "AT3230003800220020";
            salzburg.BADEGEWAESSER.filter((see) => see.BADEGEWAESSERID === wallersee).forEach((see) => {
                setWaterTemperature({
                    waterTemperature: see.MESSWERTE[0].W,
                    date: see.MESSWERTE[0].D,
                });
            });
        })();
    }, []);

    return (
        <div className="paddingTop">
            <H1>Das wichtigste auf einem Blick</H1>
            <VisibilityDetector onVisibilityChange={(data) => handleVisibilityChange(data, "landingCard")}>
                <ScrollableFlexBox>
                    <div style={{ position: "absolute", right: "10px" }}>
                        <FontAwesomeIcon className="scroll-indicator" icon={faChevronRight} style={{ color: "#a9a9a9" }} />
                    </div>
                    <LandingCard className="landingCard">
                        <h1>Öffnungszeiten</h1>
                        <div>
                            {isOpen ? (
                                <>
                                    Heute haben wir{" "}
                                    <span
                                        style={{
                                            fontWeight: "bold",
                                            textTransform: "uppercase",
                                            color: "#fda933",
                                        }}
                                    >
                                        geöffnet
                                    </span>
                                    <p style={{ textAlign: "center" }}>
                                        Montag bis Sonntag geöffnet <br />
                                        von 07:30-22:00
                                        <br />
                                        Saison von 01.03 bis 31.10
                                    </p>
                                </>
                            ) : (
                                <>
                                    <div style={{ textAlign: "center" }}>
                                        Wir haben an folgenden Tagen nur geöffnet, <br /> falls das Wetter es zulässt.
                                    </div>
                                    <p style={{ textAlign: "center" }}>
                                        Freitag bis Sonntag <br /> (inkl. Feiertage) <br />
                                        von 11:00-22:00
                                    </p>
                                </>
                            )}
                        </div>
                    </LandingCard>
                    <LandingCard className="landingCard blue">
                        <h1>Wassertemperatur</h1>
                        <div className="waterTemperatur">Perfekt zum Baden :)</div>
                        <p>{waterTemperature.date}</p>
                    </LandingCard>
                    <LandingCard className="landingCard">
                        <h1>Wetter</h1>
                        <div className="weather">
                            {weather.temperature}°C / {weather.humidity}%
                        </div>
                        <p>{weather.date}</p>
                    </LandingCard>
                </ScrollableFlexBox>
            </VisibilityDetector>
        </div>
    );
}
