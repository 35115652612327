import React from 'react';
import ReactDOM from 'react-dom';
import StartPage from './components/StartPage';
import Header from './components/Header';
import Footer from './components/Footer';
import Impressum from './components/Impressum';
import Datenschutz from './components/Datenschutz';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { CookieBanner } from '@palmabit/react-cookie-law';

// styles
import './styles/Page.css';
import './styles/index.css';
import './styles/Start.scss';
import './styles/Header.scss';
import './styles/Footer.scss';


ReactDOM.render(
	<React.StrictMode>
		<HashRouter>
			<div id="pageWrap">
				<Header />
				<Switch>
					<Route path="/" component={StartPage} exact />
					<Route path="/Impressum" component={Impressum} />
					<Route path="/Datenschutz" component={Datenschutz} />
				</Switch>
				<Footer />
			</div>
		</HashRouter>

		<CookieBanner
			message="Wir möchten die Informationen auf dieser Webseite und auch unsere Leistungsangebote auf Ihre Bedürfnisse anpassen. Zu diesem Zweck setzen wir sog. Cookies ein. Entscheiden Sie bitte selbst, welche Arten von Cookies bei der Nutzung unserer Website gesetzt werden sollen. Weitere Informationen erhalten Sie in unserer Datenschutzerklärung."
			wholeDomain={true}
			showPreferencesOption={false}
			showMarketingOption={false}
			onDeclineStatistics={() => {
				window['ga-disable-UA-163432335-2'] = true;
			}}
			policyLink="#/Datenschutz"
			necessaryOptionText="Benötigt"
			statisticsOptionText="Statistik"
		/>
	</React.StrictMode>,
	document.getElementById('root')
);
